<template>
  <CContainer class="d-flex align-items-center justify-content-center min-vh-100">
  <div class="">
		<h4>Políticas de Privacidad</h4>
		<p>En el marco de desarrollo del contrato de Servicios de METAV SPA y SVK DEPORTES SpA, (PACIFIC GYM, la empresa) el profesional y/o evaluador destinado por estas, a la medición metabólica y entrada de datos a la Plataforma META V, tendrá acceso a información metabólica, nutricional, médica personal, de documentos, procedimientos, registros médicos, uso de softwares, aplicaciones, campañas y en general a información perteneciente a cliente y/o usuario, tanto en formatos físicos como digitales.</p>
		<p>Con el objeto de regular y definir las condiciones de confidencialidad respecto de los negocios que se desarrollen con clientes de la empresa en común, o exista participación de las partes en proyectos, campañas, desarrollo de software, desarrollo de páginas web, aplicaciones, plataformas y otros deberá mantener sobre los documentos e información privada de los clientes y/o usuarios.</p>
		<p>La información confidencial incluirá lo siguiente:</p>
		<ol>
		<li>Información escrita, gráfica, computacional, electrónica o de cualquier otra especie referente a la historia médica, nutricional , metabólica operaciones, mediciones , indicadores de grasa , ventas, marketing, aspectos legales y la situación financiera y económica de alguna de las Partes o sus proyectos, incluyendo documentos, archivos, estados financieros, información contable, contratos, informes, memorandos y cualquier otra información relacionada con alguna de las Partes o sus proyectos;</li>
		<li>Información comunicada oralmente por alguna de las Partes y que se circunscriban expresamente a los temas recientemente mencionados;</li>
		<li>Todo material, muestra, fórmula, proceso, sistema, patente de invención, modelo de utilidad, software, archivo computacional, manuales de operación y manuales de elaboración, listado de componentes;</li>
		<li>Toda otra información no contemplada en las letras anteriores que, en definitiva, pueda serle útil para la correcta ejecución de las actividades indicadas en la cláusula primera precedente; y</li>
		<li>Toda otra de similares características que se circunscriban expresamente a los temas recientemente mencionados.</li>
		</ol>
		<p>Sin perjuicio de lo dispuesto en los puntos anteriores, el evaluador, se compromete de buena fe a mantener el estricto carácter de confidencial de cualquier información no pública relacionada con el presente instrumento y de las negociaciones efectuadas en la consecución del objeto de la presente clausula.</p>
		<p>Al aceptar el evaluador esta cláusula al ingresar datos a la plataforma Meta V acepta; específicamente, reconoce y acuerda que las mediciones documentos e información a los cuales tendrán acceso en el desarrollo de sus funciones, son secretos comerciales confidenciales y de propiedad exclusiva de Meta V SpA, de sus mandantes y/o clientes y que todos los documentos y materiales que se entreguen en forma independiente o complementaria son igualmente secretos comerciales privados, los cuales se encuentran amparados por la normativa de propiedad intelectual y de exclusiva pertenencia de la parte propietaria.</p>
		<p>El profesional o persona destinada al ingreso de la medición a la plataforma tecnológica, por parte de la empresa sea esta de SVK Deportes, Pacific Gym o sus relacionadas, no podrá, sin contar con la autorización previa manifestada por escrito por parte de la administración de Meta V SpA, suministrar ninguna copia (ni digital ni física) de los documentos e información de la empresa, así como, del servicio del cual forma parte a ninguna persona o entidad, que no participe directa y formalmente del proceso de análisis y evaluación a realizar. Además, tanto las partes se comprometen a hacer el mayor esfuerzo para limitar el conocimiento y acceso de los documentos e información del servicio, solamente a aquellos profesionales quienes, dentro del y alcance ordinario del trabajo, requieren indispensablemente tener conocimiento de los mismos.</p>
		<p>El profesional y/o evaluador, destinado al ingreso de datos de medición metabólica a la plataforma tecnológica se abstendrá de:</p>
		<ol>
		<li>Hacer ninguna divulgación sobre los documentos e información de la empresa no del servicio excepto que sea autorizado por escrito por la administración de Meta V SpA.</li>
		<li>Mantener estricta reserva y secreto de toda la información asociada a los servicios aprobados, vendidos, cotizados, realizados, desarrollados, adoptando las medidas pertinentes a fin de salvaguardar la confidencialidad de los documentos e información de los servicios, medidas que serán, a lo menos, las mismas que generalmente se emplean a fin de proteger sus propios documentos, información privada y sus secretos comerciales;</li>
		<li>No utilizar y/o revelar nunca y bajo ninguna forma los documentos e información de los servicios.</li>
		</ol>
		<p>El profesional o persona destinada al ingreso de la medición a la plataforma tecnológica acepta y declara que el incumplimiento de los puntos precedentes, será considerado como una falta grave a la confianza y a las obligaciones que se imponen contrato individual de trabajo y adicionalmente el evaluador acepta y avalúa, en forma libre y espontánea como indemnización anticipada de los perjuicios la cantidad de UF 1.000.- (mil unidades de fomento), siendo esto, causal de término de contrato ipso facto, liberando a las obligaciones que impone el contrato a la empresa facultándole a retener y hacerse pago y/o facturaciones futuras hasta el monto de UF 1.000.- sin perjuicio de las acciones legales que pudieran corresponder por eventuales infracciones a la normativa sobre la propiedad intelectual, penal o civil. Cabe hacer presente que esta obligación se extiende aun después de producida la terminación del contrato por un plazo de 4 años, plazo en el cual no podrán contactar o realizar trabajo alguno, por cuenta propia o ajena, en el país o en el extranjero con el mandante o con cualquiera que haya participado, activa o pasivamente o de cualquier modo en los puntos expresados precedentemente.</p>
  </div>
	</CContainer>
</template>

<script>
export default {
  name: 'Politicas',
  data () {
    return {
    }
  },
	methods: {
	}
}
</script>
